import { defineStore } from 'pinia/dist/pinia.esm-browser';
import { reactive, ref } from 'vue';
import Message from '@/models/message';
import { ChatTopEnum, ChatType } from '@/enum/chat';
import dayjs from 'dayjs';
import useUserStore from '@/store/user';
import { getMsgTime } from '@/utils/time';

const useList = () => {
  const list = ref([]);
  let timer = null;

  const refreshTime = () => {
    timer = setTimeout(() => {
      console.log(`[${dayjs().format('YYYY-MM-DD HH:mm:ss')}] 更新对话列表__msg_time时间`);

      list.value.forEach((item) => {
        item.__msg_time = getMsgTime(item.updated_at);
      });

      refreshTime();
    }, 10 * 1000);
  };

  refreshTime();

  return list;
};

const useChatListStore = defineStore('chatList', () => {
  const list = useList();
  const loading = ref(false);
  const error = ref(null);

  // 是否还有更多数据
  const moreData = ref(true);

  const page = ref(1);
  const msgNum = reactive({
    all: 0,
    new: 0,
    unanswered: 0,
    key_customers: 0,
  });

  let abortController;

  // 用来做去重的map
  const msgMap = new Map();

  const clearError = () => {
    error.value = null;
  };

  const deleteItemByRoomId = (room_id) => {
    const index = list.value.findIndex((item) => item.room_id === room_id);
    list.value.splice(index, 1);
  };

  const setMapItem = (room_id, update_time) => {
    const mapItem = msgMap.get(room_id, update_time);
    if (mapItem) {
      mapItem.update_time = update_time;
      return;
    }

    msgMap.set(room_id, {
      update_time,
    });
  };

  /**
   * 删除时间更久远的item
   * @param room_id
   * @param update_time
   * @returns {boolean} 是否删除
   */
  const removeOlderItem = (room_id, update_time) => {
    const mapItem = msgMap.get(room_id);

    if (!mapItem) {
      setMapItem(room_id, update_time);
      return true;
    }

    if (mapItem.update_time > update_time) return false;

    deleteItemByRoomId(room_id);
    setMapItem(room_id, update_time);
    return true;
  };

  // 不会发生重复item的push方法
  const noRepeatPush = (item) => {
    const { room_id, updated_at } = item;
    if (removeOlderItem(room_id, updated_at)) {
      list.value.push(item);
    }
  };

  const clearList = () => {
    list.value = [];
    msgMap.clear();
  };

  const getMoreData = async ({ nickname, token, type, tab }) => {
    if (!moreData.value) return;
    if (loading.value) return;
    loading.value = true;
    try {
      abortController = new AbortController();
      const res = await Message.getFreeChatList({
        page: page.value,
        token,
        signal: abortController.signal,
        nickname,
        type,
        tab,
      });

      if (!Array.isArray(res.list)) {
        loading.value = false;
        return;
      }

      const { total_page = 1, current_page = 1 } = res.pager;

      if (current_page === 1) {
        list.value = [];
      }

      res.list.forEach((item) => {
        item.__msg_time = getMsgTime(item.updated_at);
        noRepeatPush(item);
      });

      if (page.value >= total_page) {
        moreData.value = false;
      }

      page.value = current_page + 1;
    } catch (e) {
      if (!e.__CANCEL__) {
        error.value = e;
      }
    } finally {
      loading.value = false;
    }
  };

  const getMsgNum = (() => {
    let loading = false;
    const abortControllers = [];
    const fn = async ({ token, tab }) => {
      if (loading) return;
      loading = true;
      try {
        const res = await Promise.all(
          Object.values(ChatType).map((type) => {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            return Message.getFreeChatList({
              page: 1,
              size: 1,
              token,
              signal: abortController.signal,
              type,
              tab,
            });
          }),
        );

        const [allPager, newPager, unansweredPager, keyCustomersPager] = res.map(
          (item) => item.pager || {},
        );

        msgNum.all = allPager.total_count || 0;
        msgNum.new = newPager.total_count || 0;
        msgNum.unanswered = unansweredPager.total_count || 0;
        msgNum.key_customers = keyCustomersPager.unread_num || 0;
      } finally {
        loading = false;
        clearAbortControls();
      }
    };

    fn.reset = () => {
      loading = false;
      abortControllers.forEach((abortController) => abortController?.abort?.());
      clearAbortControls();
    };

    const clearAbortControls = () => abortControllers.splice(0);

    return fn;
  })();

  let updateListMarkTime;
  const updateList = async (token) => {
    if (loading.value) return;
    if (page.value <= 1) return;

    const currentTime = dayjs().valueOf();
    updateListMarkTime = currentTime;
    const promiseList = [];
    for (let i = 1; i <= page.value - 1; i++) {
      const promise = Message.getFreeChatList({
        page: i,
        token,
      });
      promiseList.push(promise);
    }

    const res = await Promise.all(promiseList);
    if (updateListMarkTime !== currentTime) return;
    const newList = res.map((item) => item.list).flat(1);
    clearList();
    newList.forEach((item) => noRepeatPush(item));
  };

  const assistantList = ref(null);
  const updateAssistantList = async (assistant) => {
    const teacherId = assistant.split('_')[1];
    const token = userStore.getTeacherByUid(teacherId).token;
    assistantList.value = await Message.getFreeChatList({
      token,
      type: '10',
    });
    list.value = assistantList.value.list;
  };

  const reset = () => {
    clearList();
    updateListMarkTime = dayjs().valueOf();
    abortController && abortController.abort();
    loading.value = false;
    moreData.value = true;
    page.value = 1;
    error.value = null;
    abortController = null;
  };

  const resetMsgNum = () => {
    msgNum.all = 0;
    msgNum.new = 0;
    msgNum.unanswered = 0;
    getMsgNum.reset?.();
  };

  const getIndexByRoomId = (roomId) => {
    return list.value.findIndex((item) => String(item.room_id) === String(roomId));
  };

  const getChatItemByRoomId = (roomId) => {
    return list.value.find((item) => String(item.room_id) === String(roomId));
  };

  const setToTopStatus = (roomId, is_top) => {
    const roomInfo = getChatItemByRoomId(roomId);
    if (!roomInfo) return;
    roomInfo.is_top = !!is_top ? ChatTopEnum.TOP : ChatTopEnum.NOT_TOP;
  };
  const assistantChatType = {
    1: '用户',
    2: '老师',
  };
  const cusType = ref('用户');
  const insertChatItem = (chatItem) => {
    if (chatItem.type && assistantChatType[chatItem.type] !== cusType.value) return;
    // 下面的是排序了
    const topList = [];
    const notTopList = [];
    list.value.forEach((item) => {
      if (item.is_top) {
        topList.push(item);
        return;
      }
      notTopList.push(item);
    });

    if (chatItem.is_top) {
      topList.unshift(chatItem);
    } else {
      notTopList.unshift(chatItem);
    }

    list.value = topList.concat(notTopList);
  };

  const updateChatItem = (data) => {
    // 如果是第一页还在加载中，则不更新
    if (loading.value && page.value === 1) return;

    const { room_id, is_sort, updated_at } = data;
    const index = getIndexByRoomId(room_id);
    if (index < 0) {
      const newChatItem = {
        notice: data.notice,
        is_top: data.is_top,
        updated_at: data.updated_at,
        text: data.text,
        is_me: data.is_me,
        is_read: data.is_read,
        room_id: data.room_id,
        unanswer: data.is_unanswer,
        service_status: data.service_status,
        create_time: '刚刚',
        is_process_service: data.is_process_service,
        __msg_time: getMsgTime(data.updated_at),
      };

      if (data.user) {
        newChatItem.nickname = data.user.nickname;
        newChatItem.avatar = data.user.avatar;
        newChatItem.level = data.user.level;
        newChatItem.level_image = data.user.level_image;
      }
      setMapItem(room_id, updated_at);
      insertChatItem(newChatItem);
      return;
    }

    // 如果消息列表里的消息，比新收到的更新，则不需要更新
    const item = list.value[index];
    if (item.updated_at > data.updated_at) {
      return;
    }

    setMapItem(room_id, updated_at);

    let chatItem;
    if (is_sort) {
      chatItem = list.value.splice(index, 1)[0];
    } else {
      chatItem = list.value[index];
    }

    chatItem.notice = data.notice;
    chatItem.is_top = data.is_top;
    chatItem.updated_at = data.updated_at;
    chatItem.text = data.text;
    chatItem.is_me = data.is_me;
    chatItem.is_read = data.is_read;
    chatItem.unanswer = data.is_unanswer;
    chatItem.service_status = data.service_status;
    chatItem.is_process_service = data.is_process_service;
    chatItem.__msg_time = getMsgTime(data.updated_at);

    if (data.user) {
      chatItem.nickname = data.user.nickname;
      chatItem.avatar = data.user.avatar;
      chatItem.level = data.user.level;
      chatItem.type = data.user.type;
    }
    // chatItem.level_image = data.user.level_image;

    if (is_sort) {
      chatItem.create_time = '刚刚';
      insertChatItem(chatItem);
    }
  };

  const userStore = useUserStore();
  const readChatItem = (teacher_uid, user_id) => {
    const chatItem = list.value.find((item) => item.ask_uid === user_id);
    if (!chatItem) return;
    const notice = chatItem.notice;
    chatItem.notice = 0;
    const teacher = userStore.getTeacherByUid(teacher_uid);
    const teacherNotice = teacher.notice;
    userStore.updateTeacherNotice(teacher_uid, teacherNotice - notice);
  };

  const updateNicknameRemark = (user_id, nicknameRemark) => {
    const chatItem = list.value.find((item) => item.ask_uid === user_id);
    if (!chatItem) return;
    chatItem.nickname_remark = nicknameRemark;
  };

  /**
   * 更新对话的屏蔽状态
   * @param room_id
   * @param status
   */
  const updateChatShield = (room_id, status) => {
    const item = list.value.find((item) => String(item.room_id) === String(room_id));
    if (!item) return;

    item.is_black = status;
  };

  return {
    list,
    loading,
    moreData,
    error,
    msgNum,
    cusType,
    clearError,
    getMoreData,
    getMsgNum,
    reset,
    resetMsgNum,
    setToTopStatus,
    updateChatItem,
    readChatItem,
    updateNicknameRemark,
    updateList,
    updateChatShield,
    updateAssistantList,
    getChatItemByRoomId,
  };
});

export default useChatListStore;
